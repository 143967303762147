import './App.css';// modify placeholder
import Amplify, { Apmlify, API, Storage, StorageProvider, Auth, selectInput } from 'aws-amplify';
import awsExports from './aws-exports';
import { Flex, withAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css'
// import { SignOutButton } from './ui-components';
import { SignOutButton } from './ui-components';
import { memo, useEffect, useState } from "react"
import React from "react";
import axios from "axios";
import styled from 'styled-components'

Amplify.configure(awsExports); //aws credential setting etc

function App(user) {
  
  //console.log(user)

  //検索結果格納
  const [data, setData] = useState([]);

  //検索文字列格納
  const [userInput, setUserInput] = useState([]);

  //MyCollection格納
  const [mycollection, setCollection] = useState([]);

  //MyCollectionの表示
  const [show, toggleShow ] = useState(false); 

  //Commentの表示判定
  const [commentShow, commentToggleShow ] = useState(false); 

  //表示Commentの格納
  const [comments, setComment] = useState([]);

  //投稿Commentの格納
  const [commentInput, setCommentInput] = useState([]);

  //検索画像格納
  const [image, setImage] = useState([]);

  //文字列検索
  const handleSearchSubmit = (event) => {
    event.preventDefault();
    axios({
      url : "https://5vtriplohk.execute-api.ap-northeast-1.amazonaws.com/dev/paintings",
      method : "GET",
      dataResponse : "json",
      params:{
        query : userInput
      }
    }).then((response) => {
      console.log(response); 
      console.log(response.data); 
      console.log(response.data.hits); 
      console.log(response.data.hits.hits);      
      setData(response.data.hits.hits)
    })
  }

  //MyCollectionの取得
  const handleMyCollectionSubmit = (event) => {
    event.preventDefault();
    axios({
      url : "https://5vtriplohk.execute-api.ap-northeast-1.amazonaws.com/dev/fetchmycollection",
      method : "PUT",
      dataResponse : "json",
      params:{
        query : user.user.attributes.email //emailをidとして検索する
      }
    }).then((response) => {
      console.log(response); 
      console.log(response.data); 
      setCollection(response.data)
    })
  }
  
  // お気に入り登録
  const handleUpdateFavorite = (event) => {
      // event.preventDefault();
      console.log("handleUpdateFavorite event:" + event)
      console.log(user.user.attributes.email)
      console.log(event._source.id)
      console.log(event._source.title)
      console.log(event._source.author)
      console.log(event._source.year)
      console.log(event._source.medium)
      console.log(event._source.location)
      console.log(event._source.image_url)

    axios({
      url : "https://5vtriplohk.execute-api.ap-northeast-1.amazonaws.com/dev/updatefavoritepainting",
      method : "GET",
      dataResponse : "json",
      params:{
        userid : user.user.attributes.email,
        paintingid : event._source.id, //(paintingid)
        title : event._source.title,
        author : event._source.author,
        year : event._source.year,
        medium : event._source.medium,
        location : event._source.location,
        imgurl : event._source.image_url // (img_url)
      }
    }).then((response) => {
      alert("お気に入りに追加されました！")
      console.log(response); 
      console.log(response.data); 
    })
  }

  //画像検索
  const handleImageSearch = (event) => {
    console.log("handleImageSearch start")
    console.log("event:", event)
    console.log("event.target.files[0]:", event.target.files[0])

    const reader = new FileReader();
    reader.readAsDataURL(event.target.files[0])
    console.log("reader:", reader)
        
    reader.onload = function(){ //onloadの中でしかbase64の文字列が生成されない
      console.log("reader.result:", reader.result);
      console.log("reader.result type[0]:", reader.result.substr(0, 50));
      console.log("reader.result replace:", reader.result.replace('data:image/jpeg;base64,', ''));
      console.log("reader.result replace:", reader.result.replace('data:image/jpeg;base64,', '').substr(0, 50));

      axios({
        url : "https://5vtriplohk.execute-api.ap-northeast-1.amazonaws.com/dev/imagesearch",
        method : "GET",
        dataResponse : "json",
        params : reader.result.replace('data:image/jpeg;base64,', '').substr(0, 50)
      }).then((response) => {
        console.log(response)
        console.log(response.data)
        console.log(response.data.title)
        console.log(response.data.imgurl)
        console.log(response.data.suggesttext)
        setImage(response.data)
      })
    }
    reader.onerror = function(error){
      console.log("Error: ", error);
    }
    console.log("reader.result:", reader.result)
  }

  //Commentの取得
  const handleComments = (event) => {
    //event.preventDefault();
    commentToggleShow(!commentShow)
    console.log("handleComments:" + event)
    console.log("event._source.id: " + event._source.id)
    axios({
      url : "https://5vtriplohk.execute-api.ap-northeast-1.amazonaws.com/dev/fetchcomments",
      method : "GET",
      dataResponse : "json",
      params:{
        query : event._source.id //XXX:emailをidとして検索する→取得できない
      }
    }).then((response) => {
      console.log(response); 
      console.log(response.data); 
      setComment(response.data)
    })
  }
  
  
  // Comment投稿
  const handlePostComment = (event) => {
    event.preventDefault();
    console.log("handlePostComment event:" + event)
    axios({
      url : "https://5vtriplohk.execute-api.ap-northeast-1.amazonaws.com/dev/postcomment",
      method : "POST",
      dataResponse : "json",
      params : {
        paintingid : "id0002", //絵画を特定できないので今回はピンポイントでコメントを投稿する
        userid : user.user.attributes.email.split('@')[0],
        comment : commentInput
      }
    }).then((response) => {
      console.log(response); 
      console.log(response.data); 
      setComment(response.data)
    })
  }

  return (
    <div className="App">
      <Flex direction={"column"} alignItems={"center"}>
        <Flex direction={"column"}>
          <div className="titlearea" >
            <p className="title">
              <img style={{width : "32px"}} src="https://as1.ftcdn.net/v2/jpg/02/03/07/58/1000_F_203075873_6oUdds4GZnfKCLghScBkWed89yHGP8XM.jpg" />
              <span> </span>My Favorite Paintings</p>
              <SignOutButton style={{marginLeft : "116px", marginTop : "16px" }} />
          </div>
          <div>
            <p>Hello, {user.user.attributes.email.split('@')[0]}!</p>
            <form onSubmit={handleMyCollectionSubmit} style={{marginBottom: "24px"}}>
              <button onClick={() => toggleShow(!show)} >
                {show ? "Hide My Collection" : "Show My Collection" }
              </button>
            </form>
            {show &&
              <div>
                {mycollection.map((col, index) => {
                  return(
                    <div key={index} style={{marginBottom: "100px", width: "484px"}}>
                      <img src={col.imgurl} style={{width: "484px", marginBottom: "12px"}} />
                      <span className="material-symbols-outlined" style={{marginRight: "24px", cursor: "pointer"}}>favorite</span>
                      <span className="material-symbols-outlined" style={{marginLeft: "24px", cursor: "pointer"}}>chat</span>
                      <p style={{fontWeight: "bold", overflowWrap: "break-word"}}>{col.title}</p>
                      <p>{col.author}</p>
                      <p>{col.year} {col.medium} {col.location}</p>
                    </div>
                  )
                })} 
              </div> 
            }
          </div>
          <hr className='hr' />
          <div className='imagesearchdiv'>
            <label>画像検索　</label>
            <input id="img" type="file" accept="image/*,.png,.jpg,.jpeg,.gif" onChange={handleImageSearch} style={{marginBottom: "12px"}}/>
            <p>{image.suggesttext}</p>  
              <div id={image.paintingid} key={image.paintingid} style={{marginBottom: "12px", width: "484px"}}>
                <img src={image.imgurl} style={{width: "484px", marginBottom: "12px"}} />
                <p style={{fontWeight: "bold", overflowWrap: "break-word"}}>{image.title}</p>
                <p>{image.author}</p>
                <p>{image.year} {image.medium} {image.location}</p>
              </div>
          </div>
          <hr className='hr' />
          <div className="menu">
            <form onSubmit={handleSearchSubmit}>
              <input 
                className="searchtext" 
                placeholder="作者・作品名で検索" 
                type="text" 
                value={userInput}
                onChange={(event) => setUserInput(event.target.value)}
                /> 
            </form>
          </div>
          <div>
            {/* 検索した絵画の表示 */}
             {data.map((painting, index) => {
                return(
                  <div id={painting._source.id} key={index} style={{marginBottom: "100px", width: "484px"}}>
                    <img src={painting._source.image_url} style={{width: "484px", marginBottom: "12px"}} />
                    <span onClick={() => handleUpdateFavorite(painting)} className="material-symbols-outlined" style={{marginRight: "24px", cursor: "pointer"}}>favorite</span>
                    <span onClick={() => handleComments(painting)} className="material-symbols-outlined" style={{marginLeft: "24px", cursor: "pointer"}}>chat</span>
                      {commentShow &&
                       <div style={{border: "solid 1px", borderRadius: "10px"}}>
                        <div>
                          <form onSubmit={handlePostComment}>
                            <input
                              className='commenttext'
                              placeholder='コメントを投稿'
                              type="text"
                              value={commentInput}
                              style={{width: "356px", height: "42px", marginTop:"12px", marginBottom: "12px"}}
                              onChange={(event) => setCommentInput(event.target.value)}
                              />
                          </form>
                        </div>
                        <div>
                          {comments.map(((comment, index) => {
                            return(
                              <div key={index}>
                                <p>{comment.userid}: {comment.comment}   {comment.postdate}</p>
                              </div>
                            )
                          }))}
                        </div>
                      </div>
                      }
                    <p style={{fontWeight: "bold", overflowWrap: "break-word"}}>{painting._source.title}</p>
                    <p>{painting._source.author}</p>
                    <p>{painting._source.year} {painting._source.medium} {painting._source.location}</p>
                  </div>
                )
             })}
          </div>
        </Flex>
      </Flex>
    </div>
  );
}

export default withAuthenticator(App);
