/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import React from "react";
import {
  getOverrideProps,
  useAuthSignOutAction,
} from "@aws-amplify/ui-react/internal";
import { Button, Flex } from "@aws-amplify/ui-react";
export default function SignOutButton(props) {
  const { overrides, ...rest } = props;
  const signOutButtonThreeFiveZeroThreeTwoSevenFourOneOnClick =
    useAuthSignOutAction({ global: true });
  return (
    <Flex
      gap="0"
      alignItems="flex-start"
      position="relative"
      padding="0px 0px 0px 0px"
      {...rest}
      {...getOverrideProps(overrides, "SignOutButton")}
    >
      <Button
        display="flex"
        gap="0"
        width="fit-content"
        justifyContent="center"
        alignItems="center"
        shrink="0"
        height="40px"
        position="relative"
        size="default"
        isDisabled={false}
        variation="primary"
        children="Sign Out"
        onClick={() => {
          signOutButtonThreeFiveZeroThreeTwoSevenFourOneOnClick();
        }}
        {...getOverrideProps(overrides, "SignOutButton35032741")}
      ></Button>
    </Flex>
  );
}
